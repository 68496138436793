<template>
  <v-app>
    <div class="ma-5" v-if="loading === true">
      <v-progress-circular :size="70" :width="10" color="blue" indeterminate />
      <br />
      お客様の情報を確認中です。<br />
      処理が完了するまでお待ちください。
    </div>

    <div class="ma-5" v-if="loading === false && clinic != undefined">
      <h2 text-center>患者様登録</h2>
      患者様番号を画像認識で登録します。お手元に「{{
        clinic.name
      }}」診察券のご準備をおねがいします。<br />
      <v-img
        class="ma-5"
        height="200"
        :src="image_url"
        aspect-ratio="1.0"
        contain
      ></v-img>
      <br />
      診察券のご準備ができましたら、「カメラ」ボタンを押して撮影を開始します。
      次画面で、カメラへのアクセスを確認する表示が出ますので、カメラへのアクセスを許可してください。<br />
      <p class="text-center">
        <v-btn
          class="ma-5 text-center"
          fab
          x-large
          dark
          color="indigo"
          v-on:click="start_camera()"
        >
          <v-icon>mdi-camera</v-icon>
        </v-btn>
      </p>
    </div>
  </v-app>
</template>

<script>
import { Firebase } from "../config/firebase";
export default {
  name: "PatientPri",

  data() {
    return {
      loading: true,
      image_url: "",
      clinic: null,
      user: null,
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    user_id() {
      return this.$store.getters.user.uid;
    },
  },

  created: async function () {
    try {
      await this.fetchClinics();
    } catch (e) {
      this.error = true;
      console.log(e);
    } finally {
      this.loading = false;
    }
    this.loading = false;
    try {
      await this.fetchUsers();
    } catch (e) {
      this.error = true;
      console.log(e);
    } finally {
      this.loading = false;
    }
    this.loading = false;
  },
  methods: {
    fetchUsers: async function () {
      const userRef = await Firebase.firestore()
        .collection("Users")
        .doc(this.$store.getters.user.uid);
      await userRef.get().then((doc) => {
        if (doc.exists) {
          this.user = doc.data();
          this.user.id = doc.id;
        } else {
          console.log("No user document!");
        }
        this.error = true;
      });
    },
    fetchClinics: async function () {
      try {
        const clinicRef = await Firebase.firestore()
          .collection("Clinics")
          .doc(this.id);
        await clinicRef.get().then((doc) => {
          if (doc.exists) {
            this.clinic = doc.data();
            this.clinic.id = doc.id;
          } else {
            console.log("No clinic document!");
          }
          this.error = true;
        });
        if (this.clinic.is_card_scan == false) {
          if (this.clinic.is_form == true) {
            this.$router.push({ path: "/patient_form_regster/" + this.id });
          } else {
            this.$router.push({ path: "/patient_regster/" + this.id });
          }
        }
      } catch (e) {
        this.error = true;
        this.$router.push({
          name: "error",
          params: { message: "表示できません。" },
        });
      } finally {
        this.loading = false;
      }
      this.image_url = "/images/cards/" + this.clinic.id + ".png";
    },
    start_camera() {
      this.$router.push({ path: "/patient_regster/" + this.id });
    },
  },
};
</script>

<style></style>
